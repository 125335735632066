import React, {useState} from 'react';
import styled from '@emotion/styled';

import Row from '../../templates/Row';
import Column from '../../templates/Column';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
    background-color: #00838f;
    color: white;
`;

const TitleArea = styled.div`
    display: block;
    padding: 20px 0;
    text-align: center;
`;

const Title = styled.h2`
    
`;

const Description = styled.div`
    display: block;
    padding: 20px 0 20px 20px;
    font-size: 18px;
    margin: auto;
    max-width: 800px;
    text-align: justify;
`;

const ArrowArea = styled.div`
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
`;

const Circle = styled.div`
    border: 1px solid white;
    border-radius: 13px;
    width: 25px;
    height: 25px;
    margin: auto;
    cursor: pointer;

    &:hover {
        background-color: white;
      
        & > i {
            border-color: #00838f !important;
        }
    }
`;

const Arrow = styled.i<{isVisible: boolean}>`
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-top: ${(props) => props.isVisible ? '9px' : '0'};
    transform: ${(props) => props.isVisible ? 'rotate(-135deg)' : 'rotate(45deg)'};
`;

interface WhyProps {

}

const Why: any = (props: WhyProps) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <Content>
            <Row>
                <Column>
                    <TitleArea>
                        <Title>
                            Pourquoi Collectoogo ?
                        </Title>
                    </TitleArea>
                    <Description>
                        En tant que fan de films en tout genre, je suis bien évidemment en possession de plusieurs DVD et Bluray. Je peux les regarder plus d'une centaine de fois sans me lasser.
                        Bien évidemment, au vu de la quantité de films qui remplissent mes bibliothèques, je ne peux pas me souvenir de tout ce que j'ai.
                        Ne souhaitant plus me poser de questions (par exemple, est-ce que j'ai déjà le film convoité ? ou effectuer un achat et me rendre compte que je l'ai déjà) : il me fallait une solution.<br/>
                        {isVisible && (
                            <>
                                Sur papier ? Pourquoi pas, mais un papier ça se perd ou se dégrade facilement.<br/>
                                Sur l'ordinateur ou clé USB ? Pratique et transportable, mais pas évident de visualiser la liste des films ou d'effectuer une recherche rapidement.<br/>
                                Via un site de gestion de collection existant ? C'est la solution. Mais ils sont trop souvent orientés sur un type d'objet, avec un graphisme oublié depuis très longtemps (donc pas adapté mobile) ou encore avec des champs de saisies non souhaitables, surchargés de pubs et d'informations inutiles.<br/>
                                Donc, il me fallait un moyen de répertorier tout ça. Avec une possibilité de retrouver cette liste à tout moment, facile d'utilisation, personnalisable à souhait, sans pubs et adapté à tout type de support numérique.
                                Une solution qui me permette de retrouver mon film rapidement mais pas seulement. Pourquoi s'orienter que dans des films, quand on peut aussi s'orienter sur tous types de collections ?
                                C'est de là que vient l'idée de faire Collectoogo : votre nouveau site de gestion de collection !!!
                            </>
                        )}
                        {!isVisible && (
                            <>...</>
                        )}
                    </Description>
                    <ArrowArea>
                        <Circle onClick={() => {setIsVisible(!isVisible);}}>
                            <Arrow isVisible={isVisible}/>
                        </Circle>
                    </ArrowArea>
                </Column>
            </Row>
        </Content>
    );
}

export default Why;
import React, {useState} from 'react';
import styled from '@emotion/styled';

import Row from '../../templates/Row';
import Column from '../../templates/Column';


const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
`;

const Info = styled.div`
    text-align: center;
    padding: 20px 50px;
    color: #00838f;
    display: block;
`;

const Title = styled.h2`
    text-align: left;
`;

const Description = styled.div`
    display: block;
    text-align: justify;
    margin: auto;
`;

const Image = styled.img`
    padding: 15px;
`;

const ArrowArea = styled.div`
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
`;

const Circle = styled.div`
    border: 1px solid #00838f;
    border-radius: 13px;
    width: 25px;
    height: 25px;
    margin: auto;
    cursor: pointer;

    &:hover {
        background-color: #00838f;
      
        & > i {
            border-color: white !important;
        }
    }
`;

const Arrow = styled.i<{isVisible: boolean}>`
    border: solid #00838f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-top: ${(props) => props.isVisible ? '9px' : '0'};
    transform: ${(props) => props.isVisible ? 'rotate(-135deg)' : 'rotate(45deg)'};
`;

interface PersoProps {

}

const Perso: any = (props: PersoProps) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <Content>
            <Row>
                <Column nbCol={2}>
                    <Info>
                        <Image alt="à propos du développeur" src="/images/perso.svg"/>
                    </Info>
                </Column>
                <Column nbCol={2}>
                    <Info>
                        <Title>
                            Présentation
                        </Title>
                        <Description>
                            <b>Jonathan Bresset</b> - Développeur Web (Créateur de Collectoogo)<br/><br/>
                            Cela fait plus de 20 ans maintenant que je suis fan d'informatique. C'est pour cette raison que j'ai décidé d'y consacrer ma vie (allant de mes études, jusqu'à en faire mon travail à plein temps).<br/>
                            Depuis que j'ai acquis assez d'expériences dans le travail effectué en entreprise, j'ai pour idée d'avoir mon propre projet personnel. Il ne manquait plus qu'à trouver une idée qui pourrait, avant tout, me satisfaire.<br/>
                            {isVisible && (
                                <>
                                    Le but de ce projet est, en premier lieu, d'améliorer mes compétences dans la conception de site web. Cette valeur ajoutée me sert pour le travail que j'effectue en entreprise et inversement. Le travail en équipe m'a aussi beaucoup apporté pour faire grandir au mieux mon idée.<br/>
                                    J'ai ajouté à cette passion, l'envie d'en savoir plus sur les interfaces utilisateurs et l'expérience utilisateur (UI/UX).
                                    Cette réfléxion supplémentaire me permet de savoir si ce que je fais, me paraît simple, utile et agréable.
                                    Et cela passe principalement sur la recherche de nouveautés et d'outils de programmation récents, afin de vous apporter ce qu'il y a de meilleur dans les solutions existantes.<br/>
                                    Je n'oublie pas aussi votre satisfaction, qui est tout aussi importante pour qu'un site internet soit de meilleure qualité. Donc, en cas de besoin, n'hésitez pas à me contacter.
                                </>
                            )}
                            {!isVisible && (
                                <>...</>
                            )}
                        </Description>
                        <ArrowArea>
                            <Circle onClick={() => {setIsVisible(!isVisible);}}>
                                <Arrow isVisible={isVisible}/>
                            </Circle>
                        </ArrowArea>
                    </Info>
                </Column>
            </Row>
        </Content>
    );
}

export default Perso;